<template>
  <div class="create-meeting fs24 fc666" v-if="info">
    <!-- 案主信息 -->
    <div
      v-if="info.user"
      @click="goDetail(info.user.id)"
      class="caseOwner bgfff"
    >
      <div class="dfr">
        <!-- 头像 -->
        <div class="item-portrait-box">
          <!-- 用户头像 -->
          <img
            v-if="info.user.avatar"
            :src="info.user.avatar"
            alt=""
            class="w100 h100 object-cover"
          />
          <!-- 默认头像 -->
          <img
            v-else
            src="../assets/images/default-portrait.png"
            alt=""
            class="w100 h100"
          />
          <!-- 徽章 -->
          <div v-if="info.user.is_major_auth" class="item-badge-box">
            <img
              src="../assets/images/home-emblem.png"
              alt=""
              class="w100 h100"
            />
          </div>
        </div>
        <!-- 信息 -->
        <div class="item-message flex1">
          <div class="dfc">
            <div class="dfr cross-center">
              <!-- 姓名 -->
              <div class="fs32 fw600 fc333" v-if="info.user">
                {{ info.user.name }}
              </div>
              <!-- 职位 -->
              <div class="fs24 fw400 fc999 ml16" v-if="info.user">
                {{ info.user.job }}
              </div>
              <div class="owner-tag ml26">
                <name-plate nameplate="案主"></name-plate>
              </div>
            </div>
            <!-- 公司 -->
            <div class="mt8 dfr cross-center flex-wrap">
              <div class="fs24 fw400 fc666" v-if="info.user">
                {{ info.user.company }}
              </div>
            </div>
            <!-- 公司行业 公司规模 -->
            <div class="mt4 dfr cross-center flex-wrap">
              <div class="fs24 fw400 fcb2 dfr cross-center">
                <div class="mr16">{{ info.user.industry.name }}</div>
                {{ info.user.scale.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 基本信息 -->
    <div class="bgfff pl36 pr36 pt36 mt20">
      <app-title title="基本信息"></app-title>
      <div v-if="info.host_user" class="item-cell dfr cross-center">
        <div class="item-left host">主持人</div>
        <div class="item-right avatar-box w100 fc333 dfr cross-center">
          <div class="moderator-avatar-box dfr cross-center">
            <div class="avatar">
              <img
                :src="info.host_user.avatar"
                class="w100 h100 object-cover"
                alt=""
              />
            </div>
            <div class="name fs28 fc333">{{ info.host_user.name }}</div>
          </div>
          <div class="moderator-box">
            <name-plate
              :nameplate="info.host_user.host_user_info.level.name"
            ></name-plate>
          </div>
        </div>
      </div>
      <div v-if="info.field" class="item-cell dfr cross-center">
        <div class="item-left">所属领域</div>
        <div class="item-right">{{ info.field.name }}</div>
      </div>
      <div class="item-cell dfr cross-center">
        <div class="item-left">会议日期</div>
        <div class="item-right">{{ info.hold_date }}</div>
      </div>
      <div class="item-cell dfr cross-center">
        <div class="item-left">会议时间</div>
        <div class="item-right">{{ info.hold_time_text }}</div>
      </div>
      <!-- is_online": 1:线下 2:线上 -->
      <div v-if="info.is_online == 2">
        <div class="item-cell dfr cross-center">
          <div class="item-left">会议召开形式</div>
          <div class="item-right">线上会议</div>
        </div>
        <div class="item-cell dfr cross-center">
          <div class="item-left">腾讯会议房间号</div>
          <div class="item-right">{{ info.room_no }}</div>
        </div>
      </div>
      <div v-else>
        <div class="item-cell dfr cross-center">
          <div class="item-left">会议召开形式</div>
          <div class="item-right">线下会议</div>
        </div>
        <div class="item-cell dfr cross-start">
          <!-- meeting_space_type 1:使用平台推荐的场所 2:使用自己的场所 -->
          <div class="item-right flex1" v-if="info.meeting_space_type == 1">
            <div v-if="info.meeting_space" class="dfr cross-start">
              <div class="item-left">场所名称</div>
              <div class="flex1">
                {{ info.meeting_space.name }}
              </div>
            </div>
            <div v-if="info.meeting_space" class="dfr cross-start mt28">
              <div class="item-left">场所地址</div>
              <div class="flex1">
                {{ info.meeting_space.address }}
              </div>
            </div>
          </div>
          <div class="item-right flex1 dfr cross-start" v-else>
            <div class="item-left">场所地址</div>
            <div class="flex1">
              {{ info.userself_address }}
            </div>
          </div>
        </div>
      </div>
      <div class="item-cell dfr cross-center">
        <div class="item-left">参会私董人数</div>
        <div class="item-right">{{ info.need_directory_user_count }}</div>
      </div>
      <div v-if="info.status != 0" class="item-cell dfr cross-center">
        <div class="item-left">会议状态</div>
        <div class="fc999">{{ statusMap[info.status] }}</div>
      </div>
      <!-- 总会议费用 -->
      <div class="price-box por">
        <div class="item-price dfr cross-center main-between">
          <div class="p-l fs28">
            <span class="fc333">总会议费用</span>
            <span class="fcbbb">(私董会积分)</span>
          </div>
          <div class="p-r">
            <span class="fs40 fctheme fw500">{{
              needPrice | formatCurrency
            }}</span>
          </div>
        </div>
        <div class="center-price-item item-price dfr cross-center main-between">
          <div class="p-l fs24 fcbbb">主持人费用</div>
          <div class="p-r fs24 fc333">
            {{ this.info.host_user.host_user_info.level.price }}
          </div>
        </div>
        <div class="item-price dfr cross-center main-between">
          <div class="p-l fs24 fcbbb">
            私董费用 ( {{ SingleSidongPrice }} x
            {{ info.need_directory_user_count }} )
          </div>
          <div class="p-r fs24 fc333">
            {{
              (SingleSidongPrice * info.need_directory_user_count)
                | formatCurrency
            }}
          </div>
        </div>
      </div>
    </div>

    <!-- 会议的讨论议题 -->
    <div class="bgfff pl36 pr36 pt36 pb40 mt20">
      <app-title title="会议的讨论议题"></app-title>
      <div class="cell-box">
        <div class="cell-title fs28 fb fc333 dfr cross-center">
          <div class="t"></div>
          我的困惑是，我如何
        </div>
        <div class="inner fs28 dfr cross-center main-between fc666">
          {{ info.questions[0] }}
        </div>
      </div>
      <div class="cell-box">
        <div class="cell-title fs28 fb fc333 dfr cross-center">
          <div class="t"></div>
          这个问题是重要的，因为
        </div>
        <div class="inner fs28 dfr cross-center main-between fc666">
          {{ info.questions[1] }}
        </div>
      </div>
      <div class="cell-box">
        <div class="cell-title fs28 fb fc333 dfr cross-center">
          <div class="t"></div>
          到目前为止，为了解决这个问题我已经做了
        </div>
        <div class="inner fs28 fc666 dfr cross-center main-between">
          {{ info.questions[2] }}
        </div>
      </div>
      <div class="cell-box">
        <div class="cell-title fb fs28 fc333 dfr cross-center">
          <div class="t"></div>
          我希望小组帮助我的是
        </div>
        <div class="inner fs28 dfr cross-center main-between fc666">
          {{ info.questions[3] }}
        </div>
      </div>

      <app-title
        v-if="info.files.length"
        class="mt80"
        title="图片附件"
      ></app-title>
      <!-- 图片附件 -->
      <div class="uploader-box dfr flex-wrap">
        <div v-for="(item, index) in info.files" :key="index" class="img-box">
          <img :src="item" class="w100 h100 object-cover" alt="" />
        </div>
      </div>
    </div>

    <div
      class="b-button-box bgfff"
      v-if="info.status == 0 && userInfo && info.user.id == userInfo.id"
    >
      <div class="w100 bgfff recruiting-button">
        <div class="dfr cross-center main-between">
          <icy-button
            width="6.78rem"
            height=".8rem"
            @click.native="showPayPopup"
            >支付费用</icy-button
          >
        </div>
      </div>
    </div>
    <div
      class="b-button-box bgfff"
      v-if="info.status == 0 && userInfo && info.user.id != userInfo.id"
    >
      <div class="w100 bgfff recruiting-button">
        <div class="dfr cross-center main-between">
          <icy-button width="6.78rem" height=".8rem" @click.native="showShare"
            >分享给案主付款</icy-button
          >
        </div>
      </div>
    </div>

    <!-- 支付框 -->
    <van-popup
      :close-on-click-overlay="false"
      v-model="payPopup"
      class="pay-popup"
    >
      <div class="content-box dfc flex-center">
        <div class="dialog-box dfc flex-center">
          <div class="popup-title fs40 fc333 fb">支付会议费用</div>
          <div class="popup-content p-f w100 dfr cross-center main-between">
            <div class="popup-l fs28 fc333">需要额外购买</div>
            <div class="popup-r fs40 fctheme">{{ otherBuyPrice }}</div>
          </div>
          <div
            class="popup-content p-s w100 fs28 dfr cross-center main-between"
          >
            <div class="popup-l fc999">账户积分余额</div>
            <div class="popup-r fc333">{{ myPrice }}</div>
          </div>
          <div
            class="popup-content p-t w100 fs28 dfr cross-center main-between"
          >
            <div class="popup-l fc999">本次需要积分</div>
            <div class="popup-r fc333">{{ needPrice }}</div>
          </div>
          <div v-if="isInApplet" class="dfr w100 main-between">
            <icy-button
              :width="`${!isClickPay ? '5.1rem' : '2.4rem'}`"
              height=".8rem"
              @click.native="payAndCreateMeeting"
              >支付 ￥{{
                otherBuyPrice * this.$config.pointRMBRate
              }}</icy-button
            >
            <icy-button
              v-if="isClickPay"
              width="2.4rem"
              height=".8rem"
              @click.native="paySuccess"
              >我已支付</icy-button
            >
          </div>
          <div v-else>
            <icy-button
              width="5.1rem"
              height=".88rem"
              @click.native="payAndCreateMeeting"
              >支付 ￥{{
                otherBuyPrice * this.$config.pointRMBRate
              }}</icy-button
            >
          </div>
        </div>
        <div class="close-box" @click="payPopup = false">
          <img class="w100" src="../assets/images/popup-close.png" alt="" />
        </div>
      </div>
    </van-popup>

    <!-- 返回首页 -->
    <icy-backhome></icy-backhome>
  </div>
</template>

<script>
import { isWeiXinBrowser, isApplet } from "../utils/util";
// import dayjs from "dayjs";
import NP from "number-precision";
export default {
  data() {
    return {
      info: null,
      payPopup: false,
      myPrice: "",
      needPrice: "",
      otherBuyPrice: "",
      SingleSidongPrice: 0,
      // 会议id
      meetingId: "",
      userInfo: null,
      meetingInfo: null,
      statusMap: ["待付款", "已取消", "待召开", "会议中", "会后反馈", "已完成"],
      isInApplet: false,
      isClickPay: false,
    };
  },
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    async initPage() {
      this.isInApplet = isApplet();
      window.scrollTo(0, 0);
      this.$title("会议信息确认");
      this.meetingId = parseInt(this.$route.query.meetingId);
      await this.getSingleSidongPrice();
      await this.getDetail();
      this.initShare();
      this.getUserInfo();
      // 0 => "待付款"  1 => "已取消" 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成'
      // if (this.info.status != 0) {
      //   this.$go("meetingDetail", {
      //     id: this.meetingId,
      //     active: 0,
      //     status: this.info.status
      //   });
      // }
    },
    initShare() {
      const shareLink =
        location.origin +
        location.pathname +
        `#/meetingPaid?meetingId=${this.meetingInfo.id}`;
      this.shareLink = shareLink;
      let field = "";
      if (this.meetingInfo.field) {
        field = this.meetingInfo.field.name;
      }
      let info = `我的困惑是如何「${this.meetingInfo.questions[0]}」这对「${this.meetingInfo.questions[1]}」很重要，我针对这个问题已做了「${this.meetingInfo.questions[2]}」，我希望帮我「 ${this.meetingInfo.questions[3]}」`;

      const shareInfo = {
        title: `${this.meetingInfo.host_user.name}代你创建的${field}私董会待你支付`,
        desc: `${info}`,
        // 朋友圈标题描述
        longTitle: `${info}`,
        link: shareLink,
        imgUrl: [location.origin + "/share-icon1.png"],
      };

      this.$bus.$emit("hasShare", shareInfo);
    },
    async getSingleSidongPrice() {
      const resp = await this.$API
        .post("getSingleSidongPrice", "", false)
        .then((resp) => resp);
      if (resp.code == 1000) {
        // join 私董参加会议的统一价格
        this.SingleSidongPrice = resp.data.join;
      }
    },
    async getDetail() {
      const resp = await this.$API
        .post("meetingDetail", {
          id: this.meetingId,
        })
        .then((resp) => resp);
      if (resp.code == 1000) {
        this.meetingInfo = resp.data;
        let hold_time_text = "";
        if (resp.data.hold_time_type == 0) {
          hold_time_text = "上午(09:00-12:00)";
        } else if (resp.data.hold_time_type == 1) {
          hold_time_text = "下午(14:00-17:00)";
        } else {
          hold_time_text = "晚上(19:00-21:00)";
        }
        resp.data.hold_time_text = hold_time_text;
        this.info = resp.data;

        if (resp.data.host_user.host_user_info.level.price == 0) {
          this.needPrice = 0;
        } else {
          this.needPrice = NP.plus(
            NP.times(
              this.SingleSidongPrice,
              resp.data.need_directory_user_count
            ),
            resp.data.host_user.host_user_info.level.price
          );
        }
      }
    },
    async getUserInfo() {
      const resp = await this.$API.get("userInfo").then((resp) => resp);
      if (resp.code == 1000) {
        this.userInfo = resp.data.info;
        this.myPrice = parseFloat(resp.data.info.balance);
        if (this.myPrice >= this.needPrice) {
          this.otherBuyPrice = 0;
        } else {
          this.otherBuyPrice = Math.abs(NP.minus(this.needPrice, this.myPrice));
        }
      }
    },
    showPayPopup() {
      this.payPopup = true;
    },

    async payAndCreateMeeting() {
      this.isClickPay = true;
      const _this = this;

      if (isApplet()) {
        const resp = await this.$API
          .get("needGoAppletPay", { meeting_id: _this.meetingId })
          .then((resp) => resp);
        if (resp.code == 1000) {
          let needPay = resp.data.needPay;
          if (needPay) {
            //点击微信支付后，调取统一下单接口生成微信小程序支付需要的支付参数
            const paymentData = {
              meetingId: this.meetingId,
              amount: this.needPrice,
              myPrice: this.myPrice,
              wechatPay: this.otherBuyPrice * this.$config.pointRMBRate,
            };

            //定义path 与小程序的支付页面的路径相对应
            const path =
              "/pages/payment/payment?payParam=" +
              encodeURIComponent(JSON.stringify(paymentData));
            //通过JSSDK的api跳转到指定的小程序页面
            window.wx.miniProgram.navigateTo({ url: path });
          } else {
            _this.$toast.success("支付成功");
            _this.$goReplace("meetingDetail", { id: _this.meetingId, type: 0 });
          }
        }
      } else {
        const resp = await this.$API
          .post("sendPay", { meeting_id: _this.meetingId })
          .then((resp) => resp);
        if (resp.code == 1000) {
          //判断是否为微信内置浏览器
          if (resp.data.payment) {
            if (isWeiXinBrowser()) {
              let config = resp.data.payment;
              window.WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                {
                  appId: config["appId"], //公众号ID，由商户传入
                  timeStamp: config["timeStamp"],
                  nonceStr: config["nonceStr"],
                  package: config["package"],
                  signType: config["signType"],
                  paySign: config["paySign"], // 支付签名
                },
                function (res) {
                  if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    _this.$toast.success("支付成功");
                    _this.$goReplace("meetingDetail", {
                      id: _this.meetingId,
                      type: 0,
                    });
                  }
                }
              );
            } else {
              // TODO 浏览器支付
              this.$toast("请在微信中打开");
            }
          } else {
            _this.$toast.success("支付成功");
            _this.$goReplace("meetingDetail", { id: _this.meetingId, type: 0 });
          }
        }
      }
    },
    goDetail(id) {
      this.$go("detailS", { id: id });
    },
    showShare() {
      this.$bus.$emit("showShareGuide", true);
      this.$bus.$emit("shareGuideHref", this.shareLink);
    },
    paySuccess() {
      this.isClickPay = false;
      this.payPopup = false;
      this.initPage();
    },
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.caseOwner {
  padding: 0.36rem;
  .item-inner-box {
    width: 6.94rem;
    background: #ffffff;
    margin-top: 0.28rem;
    border-radius: 0.12rem;
    box-sizing: border-box;
    overflow: hidden;
  }
  .item-portrait-box {
    width: 0.96rem;
    height: 0.96rem;
    position: relative;
    border-radius: 50%;
    img {
      border-radius: 50%;
      object-fit: cover;
    }
    .item-badge-box {
      width: 0.28rem;
      height: 0.28rem;
      position: absolute;
      right: 0;
      bottom: 0;
      img {
        position: absolute;
        object-fit: cover;
      }
    }
  }
  .item-message {
    margin-left: 0.2rem;
  }
  .owner-tag {
    transform: scale(1.2);
    transform-origin: right center;
  }
  .item-delete {
    width: 0.28rem;
    height: 0.28rem;
    margin-right: 0.36rem;
    img {
      object-fit: cover;
    }
  }

  .field-item-box {
    margin: 0.24rem 0.36rem 0.36rem 0.36rem;
    .field-item {
      background: #f6f6f6;
      padding: 0.08rem 0.16rem;
      margin: 0.12rem 0.12rem 0 0;
    }
  }
}
.create-meeting {
  width: 7.5rem;
  background: #f7f7f7;
  min-height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;

  .price-box {
    margin-top: 0.4rem;
    border-top: 0.01rem solid #eee;
    padding: 0.48rem 0 0.68rem;
    .center-price-item {
      margin: 0.48rem 0 0.16rem;
    }
  }
  .cell-box {
    padding: 0 0 0.16rem;
    margin-top: 0.36rem;
    .inner {
      min-height: 0.4rem;
      margin-top: 0.2rem;
      padding-left: 0.26rem;
      .placeholder {
        color: #cccccc;
      }
    }
  }
  .bottom-btn {
    height: 1.2rem;
  }
  .uploader-box {
    margin-top: 0.36rem;
    .img-box {
      width: 2.1rem;
      height: 2.1rem;
      border-radius: 0.08rem;
      overflow: hidden;
      margin: 0 0.22rem 0.22rem 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.pay-popup {
  background: transparent;
  .dialog-box {
    width: 6.3rem;
    height: 5.88rem;
    background: #ffffff;
    border-radius: 0.24rem;
    padding: 0 0.6rem;
    .popup-content {
      &.p-f {
        margin: 0.68rem 0 0.36rem;
      }
      &.p-t {
        margin: 0.2rem 0 0.8rem;
      }
    }
  }
  .close-box {
    width: 0.72rem;
    height: 0.74rem;
    margin-top: 0.8rem;
  }
}
.item-cell {
  font-size: 0.28rem;
  color: #666;
  margin-top: 0.28rem;
  .item-left {
    margin-right: 0.2rem;
    &.host {
      width: 5em;
    }
  }
  .item-right {
    color: #333;
    .moderator-avatar-box {
      margin-right: 0.8rem;
    }
  }
}
.line {
  height: 0.01rem;
  width: 100%;
}
.bottom {
  height: 1.2rem;
  .bottom-btn-box {
    width: 7.5rem;
    height: 1.2rem;
    position: fixed;
    bottom: 0;
    z-index: 2;
    left: 0;
    right: 0;
    background: #fff;
    padding: 0 0.36rem;
  }
}
.t {
  width: 0.12rem;
  height: 0.12rem;
  background: #333;
  transform: rotate(45deg);
  margin-right: 0.16rem;
}
.moderator-avatar-box {
  .avatar {
    width: 0.48rem;
    height: 0.48rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.12rem;
  }
}
.moderator-box {
  transform: scale(1.2);
  transform-origin: right center;
}

.b-button-box {
  height: calc(1.2rem + env(safe-area-inset-bottom));
  height: calc(1.2rem + constant(safe-area-inset-bottom));
  .recruiting-button {
    padding: 0.2rem 0.36rem;
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
    width: 7.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
